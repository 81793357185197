// 
// _buttons.scss
// 

button,a {
  outline: none !important;
}

.btn {
  padding: .5rem 1rem;
  &.btn-lg {
    padding: .6rem 1.2rem;
  }
  &.btn-sm {
    padding: .3rem .6rem;
  }
}

// .btn {
//   box-shadow: 0 3px 8px 0px rgba($gray-800, 0.25);
// }


// Rounded Buttons
.btn-rounded {
  border-radius: 30px;
}

.btn-dark, .btn-secondary{
  color: $gray-200;
}

.btn-outline-light{
  color: $gray-900;
}

// btn-soft-color

@each $color,
$value in $theme-colors {
  .btn-#{$color} {
    box-shadow: 0 3px 6px 0px rgba($gray-800, 0.18);
  }
    .btn-soft-#{$color} {
        background-color: rgba(($value), 0.25) !important;
        border-color: rgba(($value), 0.25) !important;
        color: $value !important;
        box-shadow: none;
    }
}

.btn-light {
  box-shadow: none;
}
