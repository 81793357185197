/* =============
   General
============= */

html {
  position: relative;
  min-height: 100%;
}

.h1,.h2,.h3,.h4,.h5,.h6,
h1,h2,h3,h4,h5,h6 {
  color: $gray-700;
  font-weight: 500;
}
a {
  text-decoration: none !important;
}

label {
  font-weight: 400;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid $gray-300;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid $gray-300;
  text-align: right;
}




// Icon size
.icon {
  position: relative;
  top: -2px;
}

.icon-xxs {
  height: 14px;
  width: 14px;
}

.icon-xs {
  height: 16px;
  width: 16px;
}

.icon-sm {
  height: 20px;
  width: 20px;
}

.icon-lg {
  height: 32px;
  width: 32px;
}

.icon-xl {
  height: 46px;
  width: 46px;
}

.icon-xxl {
  height: 62px;
  width: 62px;
}

.sw-3 {
  stroke-width: 3px;
}
.sw-1_5 {
  stroke-width: 1.5px;
}
.sw-1 {
  stroke-width: 1px;
}

// icon color
@each $color,
$value in $theme-colors {
  .icon-dual-#{$color} {
    color: $value;
    fill: rgba($value, 0.16);
}
    .icon-fill-#{$color} {
        fill: $value !important;
    }
}


// Custom Blockquote

.custom-blockpuote {
  &.blockquote {
    padding: 20px 20px 20px 30px;
    border-left: 3px solid;
    font-size: 16px;
  }
}

@each $color, $value in $theme-colors {
  .custom-blockpuote {
    &.blockquote {
      &.blockpuote-#{$color} {
        color: #{$value};
        border-color: #{$value};
        background-color: rgba($value, 0.15);
        .blockquote-footer {
          color: #{$value};
        }
      }
      &.blockpuote-outline-#{$color} {
        border: 1px solid #{$value};
        border-left: 4px solid #{$value};
        .blockquote-footer {
          color: #{$value};
        }
      }
    }
  }
}